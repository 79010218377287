import tw, { css, styled, theme } from 'twin.macro';

export const FOCUS_CSS = tw`focus:(outline-none ring-purple-400 border-purple-400)`;
export const FOCUS_RING_CSS = tw`focus:(outline-none ring-purple-400 ring-offset-2 ring-2)`;

// Buttons
export const ButtonTextInline = styled.button(
  FOCUS_CSS,
  tw`disabled:opacity-50 hover:underline`,
);

// Inputs
const InputBase = styled.input(FOCUS_CSS);
export const INPUT_CSS = css(
  tw`py-2 px-2 rounded-lg border-gray-800 border-2 bg-white disabled:(bg-gray-400 text-gray-800 opacity-50) placeholder-gray-400`,
  css`
    caret-color: ${theme`colors.purple-500`};
  `,
);
export const Input = styled(InputBase)(INPUT_CSS);
export const InputCheck = tw(InputBase)`text-purple-600 rounded-md`;
export const InputRadio = tw(InputBase)`text-purple-600`;
export const HelpText = tw.div`text-gray-700`;
export const LabelText = tw.span`text-gray-700 font-bold md:text-lg`;
export const LabelHeading = tw(LabelText)`block mb-3`;
export const InputGroup = tw.div`flex items-center`;
export const InputAddonRight = tw.div`relative font-bold bg-gray-200 -ml-1 p-2 border-gray-800 border-2 rounded-r-lg text-base text-gray-800`;

// Alerts
const Alert = tw.div`px-2 py-1 rounded-md`;
export const AlertError = tw(Alert)`bg-danger-200 text-danger-800`;
export const AlertInfo = tw(Alert)`bg-blue-300 text-blue-900`;
export const AlertWarning = tw(Alert)`bg-warning-200 text-warning-800`;

// Text
export const ErrorText = tw.div`text-danger-800`;
export const H3 = tw.h3`font-bold text-lg md:text-xl text-gray-800 mb-2`;

// Misc CSS
export const STORE_GRADIENT_CSS = css`
  ${tw`bg-gradient-to-br from-pink-100 to-blue-100`}
`;
export const ACCOUNT_GRADIENT_CSS = css`
  background: linear-gradient(116.54deg, #f7f4fd 16.66%, #d9e1f7 83.31%); ;
`;
export const PAGE_CONTAINER_CSS = css`
  ${tw`w-full max-w-6xl px-2 md:px-4 xl:px-0 mx-auto`}
`;
export const NO_SPIN_BUTTON_CSS = css`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &,
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
export const REACT_COLORFUL_CSS = css`
  .react-colorful__pointer {
    ${tw`(h-8 w-8 cursor-pointer borderWidth[6px] boxShadow[0 0 4px 1px rgba(0, 0, 0, 0.25)])!`}
  }
`;

export const AFTER_FADE_BOTTOM_CSS = css`
  &:after {
    ${tw`absolute block content[''] w-full bottom-0 h-16 lg:h-32`}
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 60%,
      white 100%
    );
  }
`;

// Misc
export const Container = styled.div(PAGE_CONTAINER_CSS);
export const Main = styled.main(
  PAGE_CONTAINER_CSS,
  tw`mt-4 md:mt-8 min-h-screen`,
);
export const HR = tw.hr`border-t-2 border-gray-200 rounded-full`;
export const Card = tw.div`bg-white shadow-xl rounded-3xl border border-gray-200`;
