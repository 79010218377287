export const API_URL =
  process.env.SERVER_API_URL || process.env.NEXT_PUBLIC_API_URL;

export enum StorageKeys {
  User = 'chartsy-user',
  BasketItems = 'chartsy-basket-items',
}

const { NEXT_PUBLIC_APP_STAGE_NAME } = process.env;

export const APP_STAGE_NAME: string = NEXT_PUBLIC_APP_STAGE_NAME || '';

// TODO change based on stitch type (back stitch isn't counted correctly) and
// count of fabric
export const STITCHES_PER_SKEIN = 1600;
